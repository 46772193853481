import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { httpApi } from '@app/api/http.api';
import { fetchNotifications } from '@app/store/slices/notificationsSlice';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';

interface LoginFormData {
  username: string;
  password: string;
}

export const initValues: LoginFormData = {
  username: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then(() => {
        // Here, we'll update the SocketIO SID for the logged in user
        httpApi.patch('/users/my/sid', { socketio_sid: localStorage.getItem('sid') });
        // We'll also fetch the user notifications
        fetchNotifications();
        navigate('/');
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>{t('Log In')}</Auth.FormTitle>
        <S.LoginDescription>{t('Enter your email address and password to access admin panel')}</S.LoginDescription>
        <Auth.FormItem
          name="username"
          label={t('Email')}
          rules={[{ required: true, message: t('This field is required!') }]}
        >
          <Auth.FormInput placeholder={t('Email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('Password')}
          name="password"
          rules={[{ required: true, message: t('This field is required!') }]}
        >
          <Auth.FormInputPassword placeholder={t('Password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('Remember me')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('Forgot Password')}</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('Log In')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <Auth.FooterWrapper>
          <Auth.Text>
            {t("Don't have an account? Create one")}{' '}
            <Link to="/auth/sign-up">
              <Auth.LinkText>{t('here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
