import React from 'react';
import { Col, Row } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import { RecentlyAdded } from '@app/components/contribution-dashboard/recently-added/RecentlyAdded';
import { TotalContributions } from '@app/components/contribution-dashboard/TotalContributions/TotalContributions';
import { TotalEarning } from '@app/components/contribution-dashboard/totalEarning/TotalEarning';
import { ActivityStory } from '@app/components/contribution-dashboard/activityStory/ActivityStory';
import * as S from './DashboardPage.styles';

const ContributionDashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();
  const { t } = useTranslation();

  const desktopLayout = (
    <Row>
      <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
        <Row gutter={[60, 60]}>
          <Col span={24}>
            <RecentlyAdded />
          </Col>
        </Row>
      </S.LeftSideCol>

      <S.RightSideCol xl={8} xxl={7}>
        <div id="balance">
          <TotalContributions />
        </div>
        <S.Space />
        <div id="total-earning">
          <TotalEarning />
        </div>
        <S.Space />
        <S.ScrollWrapper id="activity-story">
          <ActivityStory />
        </S.ScrollWrapper>
      </S.RightSideCol>
    </Row>
  );

  const mobileAndTabletLayout = (
    <Row gutter={[20, 24]}>
      <Col span={24}>
        <TotalContributions />
      </Col>

      <Col span={24}>
        <RecentlyAdded />
      </Col>
    </Row>
  );

  return (
    <>
      <PageTitle>{t('Contributions Dashboard')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default ContributionDashboardPage;
