import React, { useState } from 'react';
import { Avatar, Tooltip } from 'antd';
import { UserModel } from '@app/domain/users/UserModel';
import { Modal } from '@app/components/common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { UploadForm } from './UploadForm';
import * as S from './ProfileInfo.styles';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const { t } = useTranslation();

  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  return profileData ? (
    <S.Wrapper>
      <Modal
        title={t('Update Avatar Picture')}
        centered
        visible={uploadModalVisible}
        onCancel={() => setUploadModalVisible(false)}
        size="small"
        footer={null}
      >
        <UploadForm modalCallback={() => setUploadModalVisible(false)} />
      </Modal>
      <S.ImgWrapper>
        <Tooltip title={t('Update Avatar Picture')} destroyTooltipOnHide>
          <Avatar
            shape="circle"
            src={profileData?.avatar_thumbnail_url || '/images/avatar.png'}
            alt="Profile"
            onClick={() => setUploadModalVisible(true)}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      </S.ImgWrapper>
      <S.Title>{`${profileData?.name}`}</S.Title>
      <S.Subtitle>{profileData?.role?.name}</S.Subtitle>
    </S.Wrapper>
  ) : null;
};
