import React from 'react';
import { Col } from 'antd';
import * as S from './CardHeader.styles';

interface CardHeaderProps {
  title: string;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ title, children }) => {
  return (
    <S.WrapperRow justify="space-between">
      <Col>
        <S.Title level={5}>{title}</S.Title>
      </Col>

      {children && <Col>{children}</Col>}
    </S.WrapperRow>
  );
};
