import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { notificationController } from '@app/controllers/notificationController';
import { readToken } from '@app/services/localStorage.service';
import { fetchNotifications } from '@app/store/slices/notificationsSlice';
import { useDispatch } from 'react-redux';

const setSocketIOSession = (sid: string | null): void => {
  if (sid) {
    localStorage.setItem('sid', sid);
  } else {
    localStorage.removeItem('sid');
  }
};

export const useSocketIO: any = () => {
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(false);
  const [sid, setSid] = useState<string | null>(null);
  const [socketInstance, setSocketInstance] = useState<any>(null);

  useEffect(() => {
    // Connect to the Socket.IO server
    const socket = io(process.env.REACT_APP_BASE_URL || 'http://localhost:8080');

    setSocketInstance(socket);

    socket.on('connect', () => {
      console.log('Connected. ID:', socket.id);
      setIsConnected(true);
      setSid(socket.id);
      setSocketIOSession(socket.id);
      socket.emit('set-user-token', { user_token: readToken() });
    });

    socket.on('disconnect', () => {
      console.log('Disconnected');
      setIsConnected(false);
      setSid(null);
      setSocketIOSession(null);
    });

    socket.on('event', (data: any) => {
      console.log('event', data);
    });

    socket.on('notification', (data: any) => {
      notificationController.info({ message: data.title, description: data.content, duration: 5 });
      dispatch(fetchNotifications);
    });

    // We can listen to different events as well if required

    // Cleanup the Socket.IO connection
    return function cleanup() {
      // This might cause a general disconnection if more than one component use the hook ("useSocketIO()")
      socket.disconnect();
    };
  }, [dispatch]);

  return {
    isConnected,
    sid,
    socketInstance,
  };
};
