import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

const initValues = {
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
  termOfUse: true,
};

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = (values: SignUpFormData) => {
    setLoading(true);
    dispatch(doSignUp(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('New user was created'),
          description: t('Please use your new credentials to log in'),
        });
        navigate('/auth/login');
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{t('Sign Up')}</S.Title>
        <Auth.FormItem
          name="name"
          label={t('First Name')}
          rules={[{ required: true, message: t('This field is required!') }]}
        >
          <Auth.FormInput placeholder={t('First Name')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="email"
          label={t('Email')}
          rules={[
            { required: true, message: t('This field is required!') },
            {
              type: 'email',
              message: t('Please input a valid email address!'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('Email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('Password')}
          name="password"
          rules={[{ required: true, message: t('This field is required!') }]}
        >
          <Auth.FormInputPassword placeholder={t('Password')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('Confirm Password')}
          name="password_confirmation"
          dependencies={['password']}
          rules={[
            { required: true, message: t('This field is required!') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('The two passwords that you entered do not match!')));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t('Confirm Password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <Auth.Text>
                {t('I agree to the')}{' '}
                <Link to="/" target={'_blank'}>
                  <Auth.LinkText>{t('Terms of Use')}</Auth.LinkText>
                </Link>{' '}
                {t('and')}{' '}
                <Link to="/" target={'_blank'}>
                  <Auth.LinkText>{t('Privacy Policy')}</Auth.LinkText>
                </Link>
              </Auth.Text>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('Sign Up')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('Already have an account? Log in')}{' '}
            <Link to="/auth/login">
              <Auth.LinkText>{t('here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
