import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Space } from 'antd';
import { Notification } from 'components/common/Notification/Notification';
import { NotificationModel } from '@app/domain/notification/NotificationModel';
import { persistNotifications } from '@app/services/localStorage.service';
import { httpApi } from '@app/api/http.api';
import * as S from './NotificationsOverlay.styles';

interface NotificationsOverlayProps {
  notifications: NotificationModel[];
  actionWhenRead: any;
}

export const NotificationsOverlay: React.FC<NotificationsOverlayProps> = ({
  notifications,
  actionWhenRead,
  ...props
}) => {
  const { t } = useTranslation();

  const setAllNotificationsAsRead = useCallback(async () => {
    // Setting all notifications as read in the API
    await httpApi
      .put('/notifications/read_all')
      .then(async () => {
        // Clearing local notifications
        persistNotifications([]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const noticesList = useMemo(
    () =>
      notifications.map((notification, index) => {
        return (
          <Notification
            key={index}
            id={notification.id}
            type={'info'}
            title={notification.title}
            description={notification.description}
            web_action={notification.web_action}
            actionWhenRead={() => actionWhenRead()}
          />
        );
      }),
    [notifications, actionWhenRead],
  );

  return (
    <S.NoticesOverlayMenu mode="inline" {...props}>
      <S.MenuRow gutter={[20, 20]}>
        <Col span={24}>
          {notifications.length > 0 ? (
            <Space direction="vertical" size={10} split={<S.SplitDivider />}>
              {noticesList}
            </Space>
          ) : (
            <S.Text>{t('No notifications yet')}</S.Text>
          )}
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            {notifications.length > 0 && (
              <Col span={24}>
                <S.Btn
                  type="ghost"
                  onClick={() => {
                    setAllNotificationsAsRead();
                    // Calling the callback function when reading the items
                    actionWhenRead();
                  }}
                >
                  {t('Mark all as read')}
                </S.Btn>
              </Col>
            )}
          </Row>
        </Col>
      </S.MenuRow>
    </S.NoticesOverlayMenu>
  );
};
