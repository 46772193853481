import { CurrencyType } from '@app/interfaces/interfaces';

export interface ContributionValue {
  year: number;
  month: number;
  value: number;
}

export interface PreviousContribution {
  total: number;
  prevTotal: number;
  currency: CurrencyType;
  timeline: ContributionValue[];
}

export interface TotalPreviousContribution {
  total: number;
  prevTotal: number;
  currency: CurrencyType;
  timeline: ContributionValue[];
}

export const getPreviousContributions = (): Promise<PreviousContribution> => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        total: 39475,
        prevTotal: 38054.12,
        currency: 'BRL',
        timeline: [
          {
            year: 2023,
            month: 5,
            value: 42589,
          },
          {
            year: 2023,
            month: 6,
            value: 40872,
          },
          {
            year: 2023,
            month: 7,
            value: 34589,
          },
          {
            year: 2023,
            month: 8,
            value: 38762,
          },
          {
            year: 2023,
            month: 9,
            value: 49382,
          },
          {
            year: 2023,
            month: 10,
            value: 40128,
          },
          {
            year: 2023,
            month: 11,
            value: 37598.12,
          },
        ],
      });
    }, 0);
  });
};
