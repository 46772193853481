import { NotificationModel } from '@app/domain/notification/NotificationModel';
import { UserModel } from '@app/domain/users/UserModel';
const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

const testUser = {
  id: 1,
  name: 'Chris Johnson',
  avatar_thumbnail_url: avatarImg,
  username: '@john1989',
  email: 'chris.johnson@altence.com',
  phone: '+18143519459',
};

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const readToken = (): string => {
  return localStorage.getItem('accessToken') || '';
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : testUser;
};

export const persistNotifications = (notifcations: NotificationModel[]): void => {
  localStorage.setItem('notifcations', JSON.stringify(notifcations));
};

export const readNotifcations = (): NotificationModel[] => {
  const notificationsStr = localStorage.getItem('notifcations');

  return notificationsStr ? JSON.parse(notificationsStr) : [];
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
