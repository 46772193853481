import React from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { ContributionModel as Item } from '@app/domain/contributions/ContributionModel';
import { formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import * as S from './ContributionCard.styles';
import { useTranslation } from 'react-i18next';

interface ContributionCardProps {
  item: Item;
}

export const ContributionCard: React.FC<ContributionCardProps> = ({ item }) => {
  const { isTablet } = useResponsive();
  const { t } = useTranslation();

  const tabletLayout = (
    <>
      <S.InfoHeader>
        <S.InfoText>
          {t('by')} {item.contributor?.name}
        </S.InfoText>
      </S.InfoHeader>

      <S.InfoFooter>
        <S.ItemInfoWrapper>
          <S.ItemInfoHeader>{t('Type')}</S.ItemInfoHeader>
          <S.ItemValue>{item.contribution_type?.description}</S.ItemValue>
        </S.ItemInfoWrapper>

        <S.ItemInfoWrapper>
          <S.Value>{getCurrencyPrice(formatNumberWithCommas(item.value), 'BRL')}</S.Value>
        </S.ItemInfoWrapper>
      </S.InfoFooter>
    </>
  );

  const mobileLayout = (
    <>
      <S.InfoRow>
        <S.InfoText>
          {t('by')} {item.contributor?.name}
        </S.InfoText>
        <S.ItemValue>{item.contribution_type?.description}</S.ItemValue>
      </S.InfoRow>

      <S.InfoRow>
        <S.ItemInfoHeader>{t('Value')}</S.ItemInfoHeader>
        <S.Value>{getCurrencyPrice(formatNumberWithCommas(item.value), 'BRL')}</S.Value>
      </S.InfoRow>
    </>
  );

  return (
    <S.ItemCard padding={0} $img={`/images/contribution-types/${item.contribution_type_id}.png`}>
      <S.Image
        src={`/images/contribution-types/${item.contribution_type_id}.png`}
        alt={item.contribution_type?.description}
      />
      <S.Info>
        <S.InfoRow>
          <S.Title>{item.contribution_type?.description}</S.Title>
        </S.InfoRow>
        {isTablet ? tabletLayout : mobileLayout}
      </S.Info>
    </S.ItemCard>
  );
};
