import React from 'react';
import { CardProps } from '@app/components/common/Card/Card';
import * as S from './Card.styles';

interface ItemCardProps extends CardProps {
  isSider?: boolean;
}

export const Card: React.FC<ItemCardProps> = ({ isSider = false, ...props }) => {
  return <S.Card $isSider={isSider} autoHeight={false} padding={[24, 20]} {...props} />;
};
