import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { httpApi } from '@app/api/http.api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '@app/store/slices/userSlice';
import { UploadOutlined } from '@ant-design/icons';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { Upload, message } from 'antd';

export const UploadForm: React.FC<{ modalCallback: any }> = ({ modalCallback }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [file, setFile] = useState<UploadFile | null>(null);

  const handleUpload = async () => {
    setLoading(true);
    const data = new FormData();
    data.append('avatar', file as RcFile);
    httpApi
      .post('/profile/avatar', data)
      .then(({ data }) => {
        dispatch(setUser(data.data));
        notificationController.success({ message: t('Success') });
        setLoading(false);
        modalCallback();
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        if (err.options.status === 401) {
          navigate('/auth/lock');
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Upload
        name="file"
        multiple={false}
        maxCount={1}
        listType="picture"
        onChange={(info: any) => {
          setFile(info.file);
        }}
        beforeUpload={(file) => {
          const isImageFile = ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type);
          if (!isImageFile) {
            message.error(`${file.name}: ${t('File extension not allowed')}`);
          }
          return false;
        }}
      >
        <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={file === null}
        loading={isLoading}
        style={{ marginTop: 16 }}
      >
        {isLoading ? t('Uploading') : t('Upload')}
      </Button>
    </>
  );
};
