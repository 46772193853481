import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { NotificationModel } from '@app/domain/notification/NotificationModel';
import { persistNotifications, readNotifcations } from '@app/services/localStorage.service';
import { httpApi } from '@app/api/http.api';

export interface NotificationState {
  notifications: NotificationModel[];
}

const initialState: NotificationState = {
  notifications: readNotifcations(),
};

export const setNotifications = createAction<PrepareAction<NotificationModel[]>>(
  'notifications/setNotifications',
  (newNotifications) => {
    persistNotifications(newNotifications);

    return {
      payload: newNotifications,
    };
  },
);

export const fetchNotifications = createAction<PrepareAction<any>>('notifications/fetchNotifications', () => {
  const params = new URLSearchParams();
  params.append('page', '1');
  params.append('limit', '5');
  params.append('sort', JSON.stringify([{ property: 'id', direction: 'DESC' }]));
  params.append(
    'filter',
    JSON.stringify([{ property: 'is_read', value: '0', anyMatch: true, joinOn: 'and', operator: '==' }]),
  );

  // Fetching the API
  httpApi
    .get('/notifications/my', { params })
    .then(({ data }) => {
      persistNotifications(data.data);
      return {
        payload: data.data,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        payload: [],
      };
    });

  return {
    payload: [],
  };
});

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setNotifications, (state, action) => {
      state.notifications = action.payload;
    });
  },
});

export default notificationsSlice.reducer;
