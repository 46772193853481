export interface HealthFactor {
  value: number;
  name: string;
  description: string;
}

export const healthChartData: HealthFactor[] = [
  {
    value: 50,
    name: 'Lifestyle',
    description:
      'According to WHO, 60% of related factors to individual health and quality of life are correlated to lifestyle',
  },
  {
    value: 20,
    name: 'Ecology',
    description:
      'The environment is a major determinant of health, estimated to account for almost 20% of all deaths in the WHO European Region',
  },
  {
    value: 20,
    name: 'Genetics',
    description:
      'Multivariate studies show that genetic factors enhancing SWB are partly overlapping with those protecting against depression and other mental health problems',
  },
  {
    value: 10,
    name: 'Medicine',
    description:
      'Relationship between health insurance (or lack of insurance), use of medical care and health outcomes for specific conditions and types of services, and with overall health status and mortality',
  },
];
