import { ContributionModel } from '@app/domain/contributions/ContributionModel';

export const getRecentlyAddedItems = (): Promise<ContributionModel[]> => {
  return new Promise((res) => {
    res([
      {
        id: 1,
        value: 350.5,
        received_at: '2023-11-10',
        year: 2023,
        month: 11,
        document_number: null,
        comments: null,
        account_bank_code: null,
        account_number: null,
        account_branch: null,
        account_variation: null,
        contributor_id: 1,
        contributor: {
          id: 1,
          name: 'Steven Tyler',
          registered_at: '2023-10-31',
          contributor_type: 'PF',
          association_type: 'NA',
          is_collaborator: 0,
          document_number: null,
          email: null,
          comments: null,
          address: null,
          zipcode: null,
          phone: null,
          city_id: null,
          contributor_relationship_id: null,
          contact_id: null,
        },
        contribution_type_id: 1,
        contribution_type: {
          id: 1,
          description: 'Periódica',
        },
        transfer_type_id: 1,
        transfer_type: {
          id: 1,
          description: 'TED',
        },
        bank_account_id: 1,
        bank_account: {
          id: 1,
          number: '',
          branch: '',
          bank_id: 1,
          variation: null,
          description: 'Conta BB',
        },
        contributor_bank_account_id: null,
      },
      {
        id: 2,
        value: 4750.35,
        received_at: '2023-11-15',
        year: 2023,
        month: 11,
        document_number: null,
        comments: null,
        account_bank_code: null,
        account_number: null,
        account_branch: null,
        account_variation: null,
        contributor_id: 2,
        contributor: {
          id: 2,
          name: 'Joe Perry',
          registered_at: '2023-07-22',
          contributor_type: 'PF',
          association_type: 'NA',
          is_collaborator: 0,
          document_number: null,
          email: null,
          comments: null,
          address: null,
          zipcode: null,
          phone: null,
          city_id: null,
          contributor_relationship_id: null,
          contact_id: null,
        },
        contribution_type_id: 2,
        contribution_type: {
          id: 2,
          description: 'Avulsa',
        },
        transfer_type_id: 11,
        transfer_type: {
          id: 11,
          description: 'Pix',
        },
        bank_account_id: 1,
        bank_account: {
          id: 1,
          number: '',
          branch: '',
          bank_id: 1,
          variation: null,
          description: 'Conta BB',
        },
        contributor_bank_account_id: null,
      },
      {
        id: 3,
        value: 120,
        received_at: '2023-12-08',
        year: 2023,
        month: 12,
        document_number: null,
        comments: null,
        account_bank_code: null,
        account_number: null,
        account_branch: null,
        account_variation: null,
        contributor_id: 3,
        contributor: {
          id: 3,
          name: 'Adam West',
          registered_at: '2020-05-01',
          contributor_type: 'PF',
          association_type: 'NA',
          is_collaborator: 0,
          document_number: null,
          email: null,
          comments: null,
          address: null,
          zipcode: null,
          phone: null,
          city_id: null,
          contributor_relationship_id: null,
          contact_id: null,
        },
        contribution_type_id: 3,
        contribution_type: {
          id: 3,
          description: 'Atividade',
        },
        transfer_type_id: 12,
        transfer_type: {
          id: 12,
          description: 'Pagamento por Cartão',
        },
        bank_account_id: 1,
        bank_account: {
          id: 1,
          number: '',
          branch: '',
          bank_id: 1,
          variation: null,
          description: 'Conta BB',
        },
        contributor_bank_account_id: null,
      },
      {
        id: 4,
        value: 450.54,
        received_at: '2023-10-20',
        year: 2023,
        month: 10,
        document_number: null,
        comments: null,
        account_bank_code: null,
        account_number: null,
        account_branch: null,
        account_variation: null,
        contributor_id: 4,
        contributor: {
          id: 4,
          name: 'Samwise Baggins',
          registered_at: '2005-07-20',
          contributor_type: 'PF',
          association_type: 'NA',
          is_collaborator: 0,
          document_number: null,
          email: null,
          comments: null,
          address: null,
          zipcode: null,
          phone: null,
          city_id: null,
          contributor_relationship_id: null,
          contact_id: null,
        },
        contribution_type_id: 4,
        contribution_type: {
          id: 4,
          description: 'Clube 700',
        },
        transfer_type_id: 6,
        transfer_type: {
          id: 6,
          description: 'Contribuição em Dinheiro',
        },
        bank_account_id: 1,
        bank_account: {
          id: 1,
          number: '',
          branch: '',
          bank_id: 1,
          variation: null,
          description: 'Conta BB',
        },
        contributor_bank_account_id: null,
      },
      {
        id: 5,
        value: 13500,
        received_at: '2023-12-18',
        year: 2023,
        month: 12,
        document_number: null,
        comments: null,
        account_bank_code: null,
        account_number: null,
        account_branch: null,
        account_variation: null,
        contributor_id: 5,
        contributor: {
          id: 5,
          name: 'Elon Musk',
          registered_at: '2023-12-01',
          contributor_type: 'PF',
          association_type: 'NA',
          is_collaborator: 0,
          document_number: null,
          email: null,
          comments: null,
          address: null,
          zipcode: null,
          phone: null,
          city_id: null,
          contributor_relationship_id: null,
          contact_id: null,
        },
        contribution_type_id: 2,
        contribution_type: {
          id: 2,
          description: 'Avulsa',
        },
        transfer_type_id: 11,
        transfer_type: {
          id: 11,
          description: 'Pix',
        },
        bank_account_id: 1,
        bank_account: {
          id: 1,
          number: '',
          branch: '',
          bank_id: 1,
          variation: null,
          description: 'Conta BB',
        },
        contributor_bank_account_id: null,
      },
    ]);
  });
};
