import React, { useCallback } from 'react';
import { Space } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled, InfoCircleFilled, WarningFilled } from '@ant-design/icons';
import { httpApi } from '@app/api/http.api';
import { persistNotifications } from '@app/services/localStorage.service';
import { useNavigate } from 'react-router-dom';
import * as S from './Notification.styles';

interface Icons {
  info: React.ReactNode;
  success: React.ReactNode;
  warning: React.ReactNode;
  error: React.ReactNode;
  mention: React.ReactNode;
}

export type NotificationType = 'info' | 'mention' | 'success' | 'warning' | 'error';

interface NotificationProps {
  id: number;
  type: NotificationType;
  title: React.ReactNode;
  description?: React.ReactNode;
  mentionIconSrc?: React.ReactNode;
  web_action: string | null;
  actionWhenRead: any;
}

export const Notification: React.FC<NotificationProps> = ({
  id,
  type,
  mentionIconSrc,
  title,
  description,
  web_action,
  actionWhenRead,
}) => {
  const icons: Icons = {
    info: <InfoCircleFilled />,
    success: <CheckCircleFilled />,
    warning: <ExclamationCircleFilled />,
    error: <WarningFilled />,
    mention: mentionIconSrc,
  };

  const icon = icons[type] || icons.warning;

  const navigate = useNavigate();

  const setNotificationAsRead = useCallback(async (id: number) => {
    // Setting the notification as read in the API
    await httpApi
      .put(`/notifications/${id}/read`, { is_read: 1 })
      .then(async () => {
        const params = new URLSearchParams();
        params.append('page', '1');
        params.append('limit', '5');
        params.append('sort', JSON.stringify([{ property: 'id', direction: 'DESC' }]));
        params.append(
          'filter',
          JSON.stringify([{ property: 'is_read', value: '0', anyMatch: true, joinOn: 'and', operator: '==' }]),
        );
        // Reloading the notificataions
        await httpApi
          .get('/notifications/my', { params })
          .then(({ data }) => {
            persistNotifications(data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <S.SpaceWrapper
      type={type}
      align="start"
      size="middle"
      onClick={() => {
        setNotificationAsRead(id);
        // Opening the link for the notification
        if (web_action) navigate(web_action);
        // Calling the callback function when reading the item
        actionWhenRead();
      }}
    >
      {mentionIconSrc ? <S.NotificationIcon src={icon} alt="User icon" /> : icon}
      <Space direction="vertical">
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </Space>
    </S.SpaceWrapper>
  );
};
