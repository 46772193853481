import { httpApi } from '@app/api/http.api';
import { Response } from '@app/domain/APICrudModel';
// This is only used when testing/showcasing without an actual API
//import './mocks/auth.api.mock';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  reset_token: string;
  password: string;
  password_confirmation: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export const login = (loginPayload: LoginRequest): Promise<Response> =>
  httpApi.post<Response>('auth/login', { ...loginPayload }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<Response> =>
  httpApi.post<Response>('auth/register', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.put<undefined>('auth/forgot-password', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.put<undefined>('auth/reset-password', { ...newPasswordData }).then(({ data }) => data);
