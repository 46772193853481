import { CategoryType } from '../categoriesList';

export interface Component {
  name: string;
  title: string;
  url: string;
  categories: CategoryType[];
  keywords: string[];
}

// TODO review and come up with a better approach for urls
// maybe we need to have enum with all routes like we had before?

// TODO change urls according to new dashboard routes and add new NFT components
export const components: Component[] = [
  {
    name: 'Cities',
    title: 'Cities',
    url: `/settings/cities`,
    categories: ['settings'],
    keywords: ['cities', 'settings', 'city', 'cidade', 'cidades', 'tabela', 'configuração', 'configurações'],
  },
  {
    name: 'UFs',
    title: 'UFs',
    url: `/settings/ufs`,
    categories: ['settings'],
    keywords: ['uf', 'ufs', 'settings', 'estado', 'estados', 'tabela', 'configuração', 'configurações'],
  },
  {
    name: 'Banks',
    title: 'Banks',
    url: `/settings/banks`,
    categories: ['settings'],
    keywords: ['bank', 'banks', 'settings', 'banco', 'bancos', 'tabela', 'configuração', 'configurações'],
  },
  {
    name: 'Bank Accounts',
    title: 'Bank Accounts',
    url: `/settings/bank-accounts`,
    categories: ['settings'],
    keywords: [
      'bank',
      'bank accounts',
      'settings',
      'banco',
      'bancos',
      'conta',
      'conta bancária',
      'tabela',
      'configuração',
      'configurações',
    ],
  },
  {
    name: 'Currencies',
    title: 'Currencies',
    url: `/settings/currencies`,
    categories: ['settings'],
    keywords: [
      'currency',
      'currencies',
      'exchange',
      'settings',
      'moeda',
      'moedas',
      'tabela',
      'configuração',
      'configurações',
    ],
  },
  {
    name: 'Contributor Relationships',
    title: 'Contributor Relationships',
    url: `/settings/contributor-relationships`,
    categories: ['settings'],
    keywords: [
      'contributor relationship',
      'contributor relationships',
      'contributor',
      'relationships',
      'settings',
      'relação contribuinte',
      'relação do contribuinte',
      'relação',
      'contribuinte',
      'tabela',
      'configuração',
      'configurações',
    ],
  },
  {
    name: 'Contribution Types',
    title: 'Contribution Types',
    url: `/settings/contribution-types`,
    categories: ['settings'],
    keywords: [
      'contribution types',
      'contribution',
      'relationships',
      'settings',
      'tipo contribuição',
      'relação',
      'contribuição',
      'tabela',
      'configuração',
      'configurações',
    ],
  },
  {
    name: 'Transfer Types',
    title: 'Transfer Types',
    url: `/settings/transfer-types`,
    categories: ['settings'],
    keywords: [
      'transfer types',
      'contribution',
      'relationships',
      'settings',
      'transferência',
      'tipo transferência',
      'relação',
      'contribuição',
      'tabela',
      'configuração',
      'configurações',
    ],
  },
  {
    name: 'Contributor Bank Accounts',
    title: 'Contributor Bank Accounts',
    url: `/settings/contributor-bank-accounts`,
    categories: ['settings'],
    keywords: [
      'bank',
      'bank accounts',
      'contributor bank accounts',
      'settings',
      'banco',
      'bancos',
      'contribuintes',
      'conta',
      'conta bancária',
      'tabela',
      'configuração',
      'configurações',
    ],
  },
  {
    name: 'Contributors',
    title: 'Contributors',
    url: `/contributors`,
    categories: ['contributions'],
    keywords: ['contributors', 'contributions', 'contribuintes', 'tabela', 'contribuição', 'contribuições'],
  },
  {
    name: 'Contributions',
    title: 'Contributions',
    url: `/contributions`,
    categories: ['contributions'],
    keywords: ['contributions', 'contribuições', 'tabela', 'contribuição'],
  },
  {
    name: 'Reports',
    title: 'Reports',
    url: `/reports`,
    categories: ['reports'],
    keywords: ['reports', 'relatorios', 'relatórios', 'tabela', 'dados', 'graficos', 'gráficos'],
  },
];
