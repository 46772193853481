interface ResultStatus {
  id: number;
  name: string;
  desc: string;
}

export const patientResultStatus: ResultStatus[] = [
  {
    id: 1,
    name: 'Patient admission',
    desc: 'Admission of a patient means allowing and facilitating a patient to stay in the hospital unit or ward for observation, investigation, and treatment of the disease he or she is suffering from',
  },
  {
    id: 2,
    name: 'Treatment starts',
    desc: 'Part of the evaluation and treatment of any patient, regardless of condition or diagnosis, is an appropriate framework or pathway for diagnosis, treatment, and, if necessary, referral for specialty care',
  },
  {
    id: 3,
    name: "Patient's discharge",
    desc: 'A hospital will discharge you when you no longer need to receive inpatient care and can go home. Or, a hospital will discharge you to send you to another type of facility',
  },
];
