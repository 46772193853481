import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';
import { LanguageType } from '@app/interfaces/interfaces';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${readToken()}`,
    'Accept-Language': (localStorage.getItem('lng') as LanguageType) || 'en',
  };

  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  // Defining the error message type (can be either a string or an object)
  const ApiErrorMessage =
    typeof error.response?.data.meta.errors == 'string'
      ? error.response?.data.meta.errors
      : JSON.stringify(error.response?.data.meta.errors, null, 2);
  throw new ApiError<ApiErrorData>(ApiErrorMessage || error.message, {
    message: ApiErrorMessage,
    status: error.response?.status || 400,
  });
});

export interface ApiErrorData {
  message: string;
  status: number;
}
