export type CategoryType = 'settings' | 'contributions' | 'reports';

interface Category {
  name: CategoryType;
  title: string;
}

export const categoriesList: Category[] = [
  {
    name: 'settings',
    title: 'Settings',
  },
  {
    name: 'contributions',
    title: 'Contributions',
  },
  {
    name: 'reports',
    title: 'Reports',
  },
];
