import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { VerifyEmailForm } from '@app/components/auth/VerifyEmail/VerifyEmail';

const VerifyEmailPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('Verify Email')}</PageTitle>
      <VerifyEmailForm />
    </>
  );
};

export default VerifyEmailPage;
