import React from 'react';
import { LineChartOutlined, ToolOutlined, UserSwitchOutlined, DollarOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Contributors',
    key: 'contributors',
    url: '/contributors',
    icon: <UserSwitchOutlined />,
  },
  {
    title: 'Contributions',
    key: 'contributions',
    url: '/contributions',
    icon: <DollarOutlined />,
  },
  {
    title: 'Reports',
    key: 'reports',
    url: '/reports',
    icon: <LineChartOutlined />,
  },
  {
    title: 'Settings',
    key: 'settings',
    icon: <ToolOutlined />,
    children: [
      {
        title: 'Cities',
        key: 'cities',
        url: '/settings/cities',
      },
      {
        title: 'UFs',
        key: 'ufs',
        url: '/settings/ufs',
      },
      {
        title: 'Banks',
        key: 'banks',
        url: '/settings/banks',
      },
      {
        title: 'Bank Accounts',
        key: 'bank-accounts',
        url: '/settings/bank-accounts',
      },
      {
        title: 'Currencies',
        key: 'currencies',
        url: '/settings/currencies',
      },
      {
        title: 'Contributor Relationships',
        key: 'contributor-relationships',
        url: '/settings/contributor-relationships',
      },
      {
        title: 'Contribution Types',
        key: 'contribution-types',
        url: '/settings/contribution-types',
      },
      {
        title: 'Transfer Types',
        key: 'transfer-types',
        url: '/settings/transfer-types',
      },
      {
        title: 'Contributor Bank Accounts',
        key: 'contributor-bank-accounts',
        url: '/settings/contributor-bank-accounts',
      },
    ],
  },
];
