import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/contribution-dashboard/common/Card/Card';
import { formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import * as S from './TotalContributions.styles';

export const TotalContributions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <S.TitleText level={2}>{t('Current Month')}</S.TitleText>
      </Col>

      <Col span={24}>
        <Card isSider>
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <Row gutter={[14, 14]}>
                <Col span={24}>
                  <S.TitleValueText level={3}>
                    {getCurrencyPrice(formatNumberWithCommas(45879.32), 'BRL')}
                  </S.TitleValueText>
                  <S.SubtitleValueText>{t('Contribution value to date')}</S.SubtitleValueText>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
