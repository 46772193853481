import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSetNewPassword } from '@app/store/slices/authSlice';
import * as S from './NewPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';

interface NewPasswordFormData {
  password: string;
  password_confirmation: string;
}

const initStates = {
  password: '',
  password_confirmation: '',
};

export const NewPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  // Getting the reset token, which should be provided in the URL search params
  const [queryParams] = useSearchParams();
  const reset_token = queryParams.get('reset_token');

  const handleSubmit = (values: NewPasswordFormData) => {
    setLoading(true);
    dispatch(
      doSetNewPassword({
        reset_token: reset_token || '',
        password: values.password,
        password_confirmation: values.password_confirmation,
      }),
    )
      .unwrap()
      .then(() => {
        navigate('/auth/login');
        notificationController.success({
          message: t('New password was set'),
          description: t('Please, use your email with a new password to log in'),
        });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initStates}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('Back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('Create new password')}</Auth.FormTitle>
        <S.Description>{t('Your new password must be different from previous password')}</S.Description>
        <Auth.FormItem
          name="password"
          label={t('Password')}
          rules={[{ required: true, message: t('This field is required!') }]}
        >
          <Auth.FormInputPassword placeholder={t('Password')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="password_confirmation"
          label={t('Confirm Password')}
          dependencies={['password']}
          rules={[
            { required: true, message: t('This field is required!') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('The two passwords that you entered do not match!')));
              },
            }),
          ]}
          hasFeedback
        >
          <Auth.FormInputPassword placeholder={t('Confirm Password')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('Reset password')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
